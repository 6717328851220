import { getLicensee, isNewLicensee } from "./helpers"
import {
  licenseeNameField,
  phoneNumberField,
  emailField,
  licenseIdField,
  licenseStatusField,
  licenseTypeField
} from "../../../data/fields"

export const setFieldValues = values => {
  const { firstName, lastName, referenceNumber } = values
  if (isNewLicensee(referenceNumber)) {
    licenseeNameField.value = firstName + " " + lastName
    licenseStatusField.value = "Applying for License"
  } else {
    const licensee = getLicensee(referenceNumber)
    licenseeNameField.value = licensee.licenseeName
    licenseIdField.value = licensee.referenceNumber
    licenseTypeField.value = licensee.licenseType
    licenseStatusField.value = licensee.licenseStatus
  }
  phoneNumberField.value = values.phoneNumber
  emailField.value = values.email
}

export const getFields = values => {
  const fields = isNewLicensee(values.referenceNumber)
    ? [licenseeNameField, phoneNumberField, emailField, licenseStatusField]
    : [
      licenseeNameField, phoneNumberField, emailField,
      licenseIdField, licenseTypeField, licenseStatusField
    ]
  setFieldValues(values)
  return fields
}
