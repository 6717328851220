import React, { PureComponent } from "react"
import { Form, Formik } from "formik"
import Progress from "./components/widgets/Progress/"
//import { getAffid } from "./components/widgets/Affiliate/"
import User from "./components/steps/User/"
import License from "./components/steps/License/"
import Plan from "./components/steps/Plan/"
import Review from "./components/steps/Review/"
import Actions from "./components/widgets/Actions/"
import {
  UserSchema,
  LicenseSchema,
  PlanSchema,
  ReviewSchema
} from "./validation/schemas"
import plans from "./data/plans"
import { getFees } from "./components/steps/Review/fees"
import "./Wizard.css"

class Wizard extends PureComponent {
  steps = [User, License, Plan, Review]
  schemas = [UserSchema, LicenseSchema, PlanSchema, ReviewSchema]
  initial = {
    // User
    firstName: "",
    lastName: "",
    licenseNumber: "",
    email: "",
    phoneNumber: "",
    // License
    referenceNumber: "",
    // Plan
    plan: "Billed Yearly",
    // Review
    termsAndConditions: false
  }
  state = {
    step: 0
  }

  handleSubmit = values => {
    const cbInstance = window.Chargebee.init({
      site: "generalreferral",
      enableGATracking: true
    })
    const cart = cbInstance.getCart()
    let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)_fprom_tid\s*=\s*([^;]*).*$)|^.*$/, "$1");
    const customer = {
      "first_name": values.firstName,
      "last_name": values.lastName,
      "email": values.email,
      "phone": values.phoneNumber.replace(/[^0-9]+/g, ""),
      "cf_license_id": values.referenceNumber,
      "cf_join_page": window.location.href,
      "cf_license_state": "New Jersey",
      "cf_tid": cookieValue
    }
    cart.setCustomer(customer)
    const plan = plans.find(p => p.name === values.plan)
    const product = cbInstance.initializeProduct(plan.id)
    const fees = getFees(values.referenceNumber)
    const addons = fees.map(fee => ({ id: fee.id, quantity: 1 }))
    product.setAddons(addons)
    cart.replaceProduct(product)
    cart.proceedToCheckout()
  }

  previous = async props => {
    // Reset terms and conditions checkbox to avoid non-eligible licensees
    // to automatically proceed to payment.
    await props.setFieldValue("termsAndConditions", false)
    this.setState(state => ({ step: Math.max(state.step - 1, 0) }))
  }

  next = async props => {
    const errors = await props.validateForm()
    if (Object.entries(errors).length === 0) {
      this.setState(state => ({ step: Math.min(state.step + 1, this.steps.length) }))
    }
  }

  renderForm = Step => props => (
    <Form className="wizard">
      <Progress step={this.state.step} />
      <Step
        next={async () => await this.next(props)}
        {...props}
      />
      <Actions
        step={this.state.step}
        numberOfSteps={this.steps.length}
        previous={async () => await this.previous(props)}
        next={async () => await this.next(props)}
      />
    </Form>
  )

  render = () => {
    const schema = this.schemas[this.state.step]
    const Step = this.steps[this.state.step]
    return (
      <Formik
        initialValues={this.initial}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={this.handleSubmit}
        render={this.renderForm(Step)}
      />
    )
  }
}

export default Wizard
