import React from "react"
import Input from "../../core/Input"

const User = props => (
  <fieldset className="step">
    <h2 className="title">Hi there, let's get started</h2>
    <p className="subtitle">
      You will have a chance to review all fees before completing the sign up.
      <br />
      <br />
      We take your <a href="https://www.generalreferral.com/privacy" target="_blank" rel="noopener noreferrer">
      privacy</a> seriously.
    </p>
    <div className="inputpair">
      <Input
        type="text"
        name="firstName"
        placeholder=" "
        invalid={props.errors.firstName}
      />
      <label
        htmlFor="firstName"
        className="infield-label"
      >
        First Name
        <span className="reqinput">
          *
        </span>
      </label>
    </div>
    <div className="inputpair">
      <Input
        type="text"
        name="lastName"
        placeholder=" "
        invalid={props.errors.lastName}
      />
      <label
        htmlFor="lastName"
        className="infield-label"
      >
        Last Name
        <span className="reqinput">
          *
        </span>
      </label>
    </div>
    <div className="inputpair">
      <Input
        type="text"
        name="licenseNumber"
        placeholder="Must be 7 digits (optional)"
        autocomplete="nope"
        title="Leave blank or use your 7-digit real estate license number"
        invalid={props.errors.licenseNumber}
      />
      <label
        htmlFor="licenseNumber"
        className="infield-label"
      >
        Real Estate License Number
      </label>
    </div>
    <div className="inputpair">
      <Input
        type="email"
        name="email"
        placeholder=" "
        invalid={props.errors.email}
      />
      <label
        htmlFor="email"
        className="infield-label"
      >
        Email
        <span className="reqinput">
          *
        </span>
      </label>
    </div>
    <div className="inputpair">
      <Input
        type="tel"
        name="phoneNumber"
        placeholder="e.g. 888-888-8888"
        invalid={props.errors.phoneNumber}
      />
      <label
        htmlFor="phoneNumber"
        className="infield-label"
      >
        Phone Number
        <span className="reqinput">
          *
        </span>
      </label>
    </div>
  </fieldset>
)

export default User
