import * as Yup from "yup"

export const UserSchema = Yup.object().shape({
  firstName: Yup
    .string()
    .required(),
  lastName: Yup
    .string()
    .required(),
  licenseNumber: Yup
    .string()
    .matches(/^[0-9]{7}$/),
  email: Yup
    .string()
    .email()
    .required(),
  phoneNumber: Yup
    .string()
    .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
    .required()
})

export const LicenseSchema = Yup.object().shape({
  referenceNumber: Yup
    .string()
    .matches(/^[0-9]{7}$/)
    .required()
})

export const PlanSchema = Yup.object().shape({
  plan: Yup
    .string()
    .oneOf(["Billed Yearly", "Billed Every 2 Years"])
    .required()
})

export const ReviewSchema = Yup.object().shape({
  termsAndConditions: Yup
    .boolean()
    .oneOf([true])
    .required()
})
