import React from "react"
import "./Button.css"

const Button = props => {
  const type = props.type ? props.type : "button"
  const classNames = ["button"]
  if (props.color) {
    classNames.push(props.color)
  }
  if (props.hidden) {
    classNames.push("hidden")
  }

  return (
    <button
      type={type}
      className={classNames.join(" ")}
      onClick={props.onClick}>
      {props.children}
    </button>
  )
}

export default Button
