import React from "react"
import "./Progress.css"

const Progress = props => {
  const steps = ["Get Started", "License", "Plan", "Review"]
  const items = steps.map((step, index) =>
    <li key={step} className={index <= props.step ? "active" : ""}>{step}</li>
  )
  return <ul className="progress">{items}</ul>
}

export default Progress
