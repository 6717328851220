import React from "react"
import "./Message.css"

const Message = props => {
  const classNames = ["message"]
  if (props.type) {
    classNames.push(props.type)
  }

  return (
    <section className={classNames.join(" ")}>
      {props.children}
    </section>
  )
}

export default Message
