import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import Wizard from "./Wizard"

ReactDOM.render(
  <Wizard />, 
  document.getElementById("root")
)
