import React from "react"
import { Field } from "formik"
import "./Radio.css"

const Radio = props => {
  const classNames = ["radio"]
  if (props.outlined) {
    classNames.push("outlined")
  }
  if (props.invalid) {
    classNames.push("invalid")
  }

  return (
    <label className={classNames.join(" ")}>
      <Field
        type="radio"
        name={props.name}
        value={props.value}
        checked={props.checked}
      />
      <span className="icon"></span>
      {
        props.label
          ? <span className="label">{props.label}</span>
          : null
      }
    </label>
  )
}

export default Radio
