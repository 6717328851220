export const licenseeNameField = {
  name: "Licensee Name",
  description: `
    The name you are licensed under
    or the name you entered if you're a new licensee.
  `
}

export const phoneNumberField = {
  name: "Phone Number",
  description: `
    This is the phone number you entered,
    where we may send text notices.
  `,
}

export const emailField = {
  name: "Email",
  description: `
    This is the email you entered,
    where we send important communications.
  `
}

export const licenseIdField = {
  name: "License ID",
  description: `
    This is your real estate license number.
  `
}

export const licenseTypeField = {
  name: "License Type",
  description: `
    This is your current license type.
  `
}

export const licenseStatusField = {
  name: "License Status",
  description: `
    This is your existing license status.
  `
}
