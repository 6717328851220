import React from "react"
import Button from "../../core/Button"
import "./Actions.css"

const Actions = props => (
  <div className="actions">
    {
      props.step > 0 &&
      <Button color="secondary" onClick={props.previous}>Previous</Button>
    }
    {
      props.step !== props.numberOfSteps - 1
        ? <Button color="primary" onClick={props.next}>Next</Button>
        : <Button type="submit" color="primary">Begin Checkout</Button>
    }
  </div>
)

export default Actions
