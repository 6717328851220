export const initialLicenseFee = {
  id: "nj-initial-license-fee",
  name: "NJ Initial License Fee",
  price: "$160"
}

export const licenseTypeChangeFee = {
  id: "nj-license-type-change",
  name: "NJ License Type Change Fee",
  price: "$50"
}

export const licenseTransferFee = {
  id: "nj-license-transfer",
  name: "NJ License Transfer Fee",
  price: "$25"
}

export const licenseReinstatementFee = {
  id: "nj-reinstatement-fee",
  name: "NJ License Reinstatement Fee",
  price: "$150"
}

export const renewalFee = {
  id: "nj-renewal",
  name: "NJ License Renewal Fee",
  price: "$100"
}
