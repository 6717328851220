import React from "react"
import Radio from "../../core/Radio"
import plans from "../../../data/plans"
import "./Plan.css"

const Plan = props => {
  const renderPlan = (props, plan) => (
    <dl key={plan.name} className={plan.recommended ? "plan recommended" : "plan"}>
      <dt className="name">{plan.name}</dt>
      <dd className="price">{plan.price}<br /><span style={{ color: 'darkgrey', fontSize: '70%' }}>{plan.amount}</span></dd>
      {plan.benefits.map(benefit => <dd key={benefit}>{benefit}</dd>)}
      <dd className="choice">
        <Radio
          name="plan"
          value={plan.name}
          checked={props.values.plan === plan.name}
          invalid={props.errors.plan}
        />
      </dd>
    </dl>
  )

  return (
    <fieldset className="step">
      <h2 className="title">Select your plan</h2>
      <p className="subtitle">
        Still have questions? Start a <a href="/#hs-chat-open">chat</a> or learn more about <a href="https://www.generalreferral.com/plans" target="_blank" rel="noopener noreferrer"> our plans</a>.
      </p>
      <div className="plans">
        {plans.map(plan => renderPlan(props, plan))}
      </div>
    </fieldset>
  )
}

export default Plan
