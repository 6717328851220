import React, { Fragment, PureComponent } from "react"
import Spinner from "react-loading"
import Axios from "axios"
import Button from "../../core/Button"
import Radio from "../../core/Radio"
import Message from "../../core/Message"
import "./License.css"

class License extends PureComponent {
  state = {
    licensees: [],
    loading: true
  }

  componentDidMount = async () => {
    const licensees = await this.getLicensees()
    // save data in the local storage to access it from other components
    localStorage.setItem("licensees", JSON.stringify(licensees))
    this.setState({ licensees, loading: false })
  }

  getLicensees = async () => {
    const { email, phoneNumber, licenseNumber, firstName, lastName } = this.props.values
    const params = {}
    if (email.length > 0) {
      params["mail"] = email
    }
    if (phoneNumber.length > 0) {
      params["phone"] = phoneNumber
    }
    if (licenseNumber.length > 0) {
      params["reference"] = licenseNumber
    }
    if (firstName.length > 0) {
      params["first"] = firstName
    }
    if (lastName.length > 0) {
      params["last"] = lastName
    }
    const url = "https://lookup.generalreferral.com/api/licensee"
    const query = new URLSearchParams(params).toString()
    const response = await Axios.get(url + "?" + query)
    return response.data
  }

  continueAsNewLicensee = async () => {
    // all zeroes for a new licensee
    await this.props.setFieldValue("referenceNumber", "0000000")
    await this.props.next(this.props)
  }

  renderLicensee = licensee => (
    <li key={licensee.referenceNumber}>
      <Radio
        label={this.renderLicenseeLabel(licensee)}
        name="referenceNumber"
        value={licensee.referenceNumber}
        checked={this.props.values.referenceNumber === licensee.referenceNumber}
        invalid={this.props.errors.referenceNumber}
        outlined
      />
    </li>
  )

  renderLicenseeLabel = licensee => (
    <Fragment>
      Licensee Name: <strong>{licensee.licenseeName}</strong>
      <br />
      Reference Number: <strong>{licensee.referenceNumber}</strong>
      <br />
      License Status: <strong>{licensee.licenseStatus}</strong>
      <br />
      License Type: <strong>{licensee.licenseType}</strong>
    </Fragment>
  )

  render = () => {
    if (this.state.loading) {
      return <Spinner type="bubbles" className="spinner" />
    }

    const licensees = this.state.licensees.map(licensee => this.renderLicensee(licensee))
    
    return (
      <fieldset className="step">
        <h2 className="title">Select your real estate license</h2>
        {
          this.state.licensees.length === 0 &&
          <Fragment>
            <Message type="alert">
              We didn't find any matching licensees. <strong>Tip: </strong>Inputting your license
              number is optional, click "Previous" to try again or <a href="/#hs-chat-open">open a 
              support chat</a>.
            </Message>
            <Message type="warning">
            If you recently passed your exam & you would like General Referral as your sponsoring broker, proceed by clicking the "new licensee" button below.
              <footer>
                <Button color="primary" onClick={this.continueAsNewLicensee}>
                  Continue as a new licensee
                </Button>
              </footer>
            </Message>
          </Fragment>
        }
        {
          this.state.licensees.length > 0 &&
          <Fragment>
            <div className="form-group">
              <p>The following accounts match your description:</p>
              <ul className="licensees">{licensees}</ul>
            </div>
            <Message type="warning">
            If you recently passed your exam & you would like General Referral as your sponsoring broker, proceed by clicking the "new licensee" button below.
              <footer>
                <Button color="primary" onClick={this.continueAsNewLicensee}>
                  Continue as a new licensee
                </Button>
              </footer>
            </Message>
          </Fragment>
        }
      </fieldset>
    )
  }
}

export default License
