import React from "react"
import { Field } from "formik"
import "./Input.css"

const Input = props => {
  const classNames = ["field"]
  if (props.invalid) {
    classNames.push("invalid")
  }

  return (
    <Field
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      autoComplete={props.autocomplete}
      title={props.title}
      className={classNames.join(" ")}
    />
  )
}

export default Input
